import { revid, version } from './version';

export const environment = {
  production: false,
  name: 'staging',
  title: 'DigiRES staging',
  showMap: true,

  version: version,
  revid: revid,
  apiURL: '/api/*',
  udpURL: 'https://udp-staging.ext.np.renewables-apps.com/',
};
